import React from "react";

import { cn } from "@/lib/utils";

import { input, inputHelperText, inputLabel } from "./styles";
import { InputProps } from "./types";

export const Input = ({
  label,
  link,
  id,
  helperText,
  state,
  size,
  disabled,
  startAdornment,
  endAdornment,
  ...props
}: InputProps) => {
  const hasHeader = label || link;
  const overrideState = disabled ? "disabled" : state;

  return (
    <div className="flex flex-col gap-2">
      {hasHeader && (
        <div className="flex items-center justify-between gap-4">
          {label && (
            <label
              htmlFor={id}
              className={cn(inputLabel({ state: overrideState }))}
            >
              {label}
            </label>
          )}
          {link}
        </div>
      )}
      <div className="relative">
        {startAdornment && (
          <div className="absolute bottom-0 left-3 top-0 my-auto flex items-center">
            {startAdornment}
          </div>
        )}
        <input
          className={cn(
            input({
              state,
              size,
              className: props.className,
            }),
            startAdornment && "pl-10",
            endAdornment && "pr-10",
          )}
          id={id}
          disabled={disabled}
          {...props}
        />
        {endAdornment && (
          <div className="absolute bottom-0 right-3 top-0 my-auto flex items-center">
            {endAdornment}
          </div>
        )}
      </div>
      {helperText && (
        <p className={cn(inputHelperText({ state: overrideState }))}>
          {helperText}
        </p>
      )}
    </div>
  );
};
