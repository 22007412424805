import { PropsWithChildren, useState } from "react";

import { cn } from "@/lib/utils";

import { TablerIcon } from "../tabler-icon";
import { badge } from "./styles";
import { BadgeProps } from "./types";

export const Badge = ({
  type,
  color = "neutral",
  className,
  label,
  dismissible,
  onDismiss,
}: PropsWithChildren<BadgeProps>) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const handleDismiss = () => {
    setIsDismissed(true);
    onDismiss?.();
  };

  if (isDismissed) {
    return null;
  }

  return (
    <div
      className={cn(
        badge({
          type,
          color,
          className,
        }),
      )}
    >
      {label}
      {dismissible && (
        <button type="button" onClick={handleDismiss}>
          <TablerIcon name="x" className="w-3" />
        </button>
      )}
    </div>
  );
};
