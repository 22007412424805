import type { CountdownTimerTileProps } from "./types";

export const CountdownTile = ({ value, label }: CountdownTimerTileProps) => {
  return (
    <div className="[border-image:linear-gradient(180deg,rgba(255, 255, 255, 0.08)_0%,rgba(255, 255, 255, 0.48)_100%)] flex h-16 w-16 flex-col items-center justify-center gap-1 rounded-2xl border bg-gradient-countdown-timer-day sm:h-24 sm:w-24 sm:gap-[0.625rem] md:h-17 md:w-17 ">
      <span className="w-full bg-gradient-leading-colors bg-clip-text text-center text-2xl font-semibold text-transparent md:text-2xl">
        {value}
      </span>
      <span className="text-xs text-textBluish md:text-x">{label}</span>
    </div>
  );
};
