import { useContext } from "react";

import { ModalContext } from "../contexts/modal.context";

export const useModal = (
  key: string,
): { isOpen: boolean; showModal: () => void; hideModal: () => void } => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModal must be used within a ModalContextProvider");
  }

  const { modals, showModal, hideModal } = context;

  return {
    isOpen: modals[key] || false,
    showModal: () => showModal(key),
    hideModal: () => hideModal(key),
  };
};
