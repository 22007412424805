import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { TablerIcon } from "../tabler-icon";
import { actionButton } from "./styles";
import { ActionButtonProps } from "./types";

export const ActionButton = ({
  icon,
  children,
  type,
  onClickAction,
  className,
  size,
}: PropsWithChildren<ActionButtonProps>) => {
  const handleClick = () => {
    onClickAction?.();
  };

  return (
    <button
      className={cn(
        actionButton({
          type,
          className,
          size,
        }),
      )}
      type="button"
      onClick={handleClick}
    >
      {icon && <TablerIcon name={icon} className="w-4 text-gray-500" />}
      {children}
    </button>
  );
};
