export interface getUserData {
  id: string;
  email: string;
  username: string;
  createdAt: string;
  txHash: string | null;
  profile: {
    about: string;
    description: string;
    avatarUrl: string;
    backgroundImageUrl: string;
    bio: string;
    birthdate: string;
    education: [];
    roles: [];
    industries: string[];
    interests: string[];
    languages: {
      language: string;
      proficiency: string;
    };
    isSocialAccountsVerified: boolean;
    socialAccountLinks: [];
    socialAccountsData: [];
    firstName: string;
    lastName: string;
  };
  referralCode: string;
  roles: string[];
  walletAddresses: string[];
  wallets: [
    {
      id: string;
      address: string;
      cdo: string;
      scdo: string;
      tcdo: string;
      lockedScdo: string;
      lockedQuareterScdo: string;
      cdoDirectReward: string;
      cdoReward: string;
      usdc: string;
      usdcDirectReward: string;
      usdcReward: string;
    },
  ];
  registeredWithoutRef: boolean;
}

const useUserData = (): getUserData | undefined => {
  if (typeof window == "undefined") return;

  const getUserData = localStorage.getItem("candaoUser");

  if (!getUserData) return;

  if (typeof getUserData === "string") return JSON.parse(getUserData);

  return getUserData;
};

export default useUserData;
