import { useRef, useState } from "react";

import useAutosizeTextArea from "@/hooks/use-auto-size-textarea.hook";
import { Avatar, Button } from "@/v2/components/atoms";

export const NewPost = () => {
  const [value, setValue] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(true, textAreaRef.current as any, value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;

    setValue(val);
  };

  return (
    <div className="flex justify-between gap-4 rounded-2xl border border-gray-200 bg-white p-4">
      <div className="flex w-full gap-4">
        <Avatar size="small" color="indigo" rounded className="shrink-0">
          A
        </Avatar>
        <textarea
          className="w-full resize-none pt-1.5 text-base text-gray-400 outline-0"
          onChange={handleChange}
          ref={textAreaRef}
          rows={1}
          value={value}
          placeholder="What journey would you start?, Satoshi"
        />
      </div>
      <Button variant="primary" size="slim">
        Post
      </Button>
    </div>
  );
};
