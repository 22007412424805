import { Button, TablerIcon } from "@/components/atoms";
import { Input, Modal, Select, Tabs, Textarea } from "@/components/molecules";
import { cn } from "@/lib/utils";
import { getMe, patchMe } from "@/queries/users/me.query";
import { ErrorDto } from "@/types/error.dto";
import router from "next/router";
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { v4 } from "uuid";
import Image from "next/image";
import { Checkbox } from "@mui/material";
import { get } from "node:http";
import { useSearchParams } from "next/navigation";
import { useModal } from "../hooks/use-modal.hook";

interface ErrorModalIn {
  isErrorModalOpen: boolean;
  hideErrorModal: () => void;
}

const ErrorModal = ({ isErrorModalOpen, hideErrorModal }: ErrorModalIn) => {
  const searchParams = useSearchParams();
  const hasErrorCode = searchParams?.get("errorCode");

  const { showModal: showErrorModal } = useModal("error-modal");

  const errorHandling = {
    "1": {
      title: "Connect Failed",
      desc: "The email you used to log in is already connected to Candao but using a different method. <br/>We do not currently support multiple connection methods for a single account.<br /><br /> Please log in using the method you used when you first connected to Candao",
    },
    "2": {
      title: "Connect Failed",
      desc: "This account has never connected to Candao before. The first time you connect, you need to enter the refcode received by another user.<br/><br/>Click Connect in the upper right corner, enter the refcode click continue and connect your wallet.",
    },
  };


  useEffect(() => {
    if (!isErrorModalOpen && hasErrorCode) {
      showErrorModal();
    }
    console.log(isErrorModalOpen)
  }, [hasErrorCode]);

  //TODO: FIX XDDD
  const currentError = (errorHandling as any)[hasErrorCode as string];

  const handleClose = () => {
    router.push("/")
    hideErrorModal();
    localStorage.removeItem("candaoErrorHandling");
  };

  return (
    <Modal
      isOpen={isErrorModalOpen}
      onClose={() => handleClose()}
      className="max-w-lg"
    >
      <Modal.Header onClose={() => handleClose()}>
        {currentError?.title}
      </Modal.Header>
      <Modal.Body className="w-full max-w-[512px]">
        <div dangerouslySetInnerHTML={{ __html: currentError?.desc }} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleClose()}>Okey</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
