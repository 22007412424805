import { PropsWithChildren } from "react";
import ReactModal from "react-modal";

import { ActionButton } from "@/components/atoms";
import { cn } from "@/lib/utils";

import { ModalBodyProps, ModalHeaderProps, ModalProps } from "./types";


export const Modal = ({
  isOpen,
  onClose,
  children,
  className,
}: PropsWithChildren<ModalProps>) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cn(
        "mx-auto w-full rounded-2xl bg-white shadow-modal-combined outline-none max-h-[100svh]",
        className,
      )}
      overlayClassName="fixed backdrop-blur-sm bg-[#1118273D] flex items-center justify-center top-0 left-0 right-0 bottom-0 z-50"
    >
      <article>{children}</article>
    </ReactModal>
  );
};

const ModalHeader = ({
  onClose,
  children,
}: PropsWithChildren<ModalHeaderProps>) => {
  return (
    <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 text-lg font-semibold text-gray-900">
      {children}

      {onClose && <ActionButton icon="x" onClickAction={onClose} />}
    </header>
  );
};

const ModalBody = ({
  children,
  className,
}: PropsWithChildren<ModalBodyProps>) => {
  return <div className={cn("p-6 max-h-[calc(100svh_-_134px)]", className)}>{children}</div>;
};

const ModalFooter = ({
  children,
  className,
}: PropsWithChildren<ModalBodyProps>) => {
  return (
    <footer className="flex justify-end gap-2 border-t border-gray-200 px-6 py-4">
      {children}
    </footer>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
