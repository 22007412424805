import { useCallback, useRef, useState } from "react";

export function useControlled<T>({
  value,
  initialValue,
}: {
  value?: T;
  initialValue: T;
}) {
  const { current: isUncontrolled } = useRef(value === undefined);

  const [localValue, setLocalValue] = useState(initialValue);

  const safeValue = isUncontrolled ? localValue : value;

  const safeSetValue = useCallback((newValue: T) => {
    if (isUncontrolled) {
      setLocalValue(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [safeValue, safeSetValue] as const;
}
