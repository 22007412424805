import axios from "axios";
import { apiUrl } from "../queries/config/api.config";

export async function request(
  path: string,
  options?: any,
  body?: any,
  filters?: any,
) {
  let parsedBody;
  const url = new URL(`${apiUrl}${path}`);

  if (filters) {
    Object.keys(filters).forEach((i) => {
      url.searchParams?.append(i, filters[i]);
    });
  }

  if (body instanceof FormData) {
    parsedBody = body;
  } else {
    parsedBody = JSON.stringify(body);
  }

  const requestOptions = {
    method: "get",
    data: parsedBody || null,
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
  };

  try {
    const response = await axios(url.href, requestOptions);
    return { data: response.data, status: response.status };
  } catch (error: any) {
    throw { data: error.response.data, code: error.response.status };
  }
}
