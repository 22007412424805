import React, { useRef, useState } from "react";

import useAutosizeTextArea from "@/hooks/use-auto-size-textarea.hook";
import { cn } from "@/lib/utils";

import { textarea, textareaHelperText, textareaLabel } from "./styles";
import { TextareaProps } from "./types";

export const Textarea = ({
  className,
  defaultValue = "",
  enableAutosize,
  helperText,
  id,
  label,
  link,
  size,
  state,
  ...props
}: TextareaProps) => {
  const initialValue = defaultValue.toString();
  const [value, setValue] = useState(initialValue);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const hasHeader = label || link;

  useAutosizeTextArea(enableAutosize, textAreaRef, value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;

    setValue(val);
  };

  return (
    <div className="flex flex-col gap-2">
      {hasHeader && (
        <div className="flex items-center justify-between gap-4">
          {label && (
            <label htmlFor={id} className={cn(textareaLabel({ state }))}>
              {label}
            </label>
          )}
          {link}
        </div>
      )}
      <textarea
        ref={textAreaRef}
        className={cn(
          textarea({
            state,
            size,
          }),
          className,
        )}
        onChange={handleChange}
        value={value}
        id={id}
        {...props}
      />
      {helperText && (
        <p className={cn(textareaHelperText({ state }))}>{helperText}</p>
      )}
    </div>
  );
};
