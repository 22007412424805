import { useEffect } from "react";
import { useRouter } from 'next/router';

const IntercomIntegration = () => {
    const router = useRouter();

    const showIntercom = () => {
        const hiddenPaths = ['/social-graph-mobile'];
        return !hiddenPaths.some(hiddenPath => router.pathname?.startsWith(hiddenPath));
    };

    useEffect(() => {
        if (showIntercom()) {
            (function () { var w = window as any; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { (i as any).c(arguments); }; (i as any).q = []; (i as any).c = function (args: any) { (i as any).q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/wcbj403c'; var x = d.getElementsByTagName('script')[0]; (x as any).parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
            (window as any).Intercom('boot', {
                api_base: "https://api-iam.intercom.io",
                app_id: "wcbj403c",
            });
        }
   // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleRouteChange = () => {
            (window as any).Intercom('update');
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
   // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null
}

export default IntercomIntegration;