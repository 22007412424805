import { cva } from "class-variance-authority";

export const badge = cva(
  "flex items-center justify-center rounded-[4px] px-2 py-0.5 text-xs gap-2",
  {
    variants: {
      type: {
        pills: "rounded-full",
      },
      color: {
        neutral: "bg-gray-100 text-gray-800",
        indigo: "bg-indigo-100 text-indigo-800",
        red: "bg-red-100 text-red-800",
        yellow: "bg-yellow-100 text-yellow-800",
        green: "bg-green-100 text-green-800",
        blue: "bg-blue-100 text-blue-800",
        purple: "bg-purple-100 text-purple-800",
        pink: "bg-pink-100 text-pink-800",
      },
    },
    defaultVariants: {
      color: "neutral",
    },
  },
);
