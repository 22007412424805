import NextLink from "next/link";

import { TablerIcon } from "@/components/atoms";

import { link } from "./styles";
import { LinkProps } from "./types";

export const Link = ({ href, text, state, disabled = false }: LinkProps) => {
  const internalState = disabled ? "disabled" : state;

  return (
    <NextLink className={link({ state: internalState })} href={href}>
      {text}
      <TablerIcon name="arrow-right" />
    </NextLink>
  );
};
