import React, { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

import { BoxProps } from './types';

export const Box = ({
  children,
  className,
  as,
}: PropsWithChildren<BoxProps>) => {
  const Component = as || "div";
  return (
    <Component
      className={cn("overflow-hidden rounded-2xl border bg-white", className)}
    >
      {children}
    </Component>
  );
};

const BodyComponent = ({ children }: PropsWithChildren) => {
  return <div className="p-4">{children}</div>;
};

Box.Body = BodyComponent;
