import { ReactSVG } from "react-svg";

import { IconProps } from "./types";

export type { IconProps } from "./types";

export const Icon = ({ name, className = "" }: IconProps) => {
  const classList = className.split(" ");

  return (
    <ReactSVG
      src={`/assets/icons/${name}.svg`}
      wrapper="span"
      className={className}
      beforeInjection={(svg) => {
        className && svg.classList.add(...classList);
      }}
    />
  );
};
