import { request } from "../../utils/requestNew";
import { authRequest } from "../../utils/request/authRequest";

interface ReturnData {
  accessToken: string;
  refreshToken: string;
}

export const getAuthToken = async (params: {
  walletAddress?: string;
  signature?: string;
  refreshToken?: string;
}): Promise<ReturnData> => {
  let response;
  if (!params.signature) {
    response = await authRequest(
      `v1/auth/token`,
      {
        method: "POST",
        headers: {
          "Content-Type": `application/json`,
        },
      },
      {
        type: "refresh",
        refreshToken: params.refreshToken,
      },
    );
  } else {
    response = await request(
      `v1/auth/token`,
      {
        method: "POST",
        headers: {
          "Content-Type": `application/json`,
        },
      },
      {
        type: "access",
        walletAddress: params.walletAddress,
        signature: params.signature,
      },
    );
  }
  return response.data;
};
