import { cva } from "class-variance-authority";

export const tab = cva(
  "flex items-center gap-2 text-base text-gray-500 disabled:text-gray-400 hover:text-gray-600",
  {
    variants: {
      variant: {
        horizontal: "",
        vertical: "",
      },
      state: {
        active: "",
      },
      type: {
        underline: "border-b-2 border-transparent",
        pills: "",
        light: "",
      },
      size: {
        default: "px-4 py-2",
        small: "",
      },
    },
    compoundVariants: [
      {
        variant: "horizontal",
        type: "underline",
        size: "default",
        class: "py-[0.6875rem]",
      },
      {
        variant: "horizontal",
        type: "underline",
        size: "default",
        state: "active",
        class: "border-indigo-600 text-indigo-600 hover:text-indigo-600",
      },
    ],
    defaultVariants: {
      variant: "horizontal",
      type: "underline",
      size: "default",
    },
  },
);
