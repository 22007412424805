import { cva } from 'class-variance-authority';

export const dropdownButton = cva(
  "flex items-center whitespace-nowrap justify-center gap-2.5 rounded-[4px] transition-all text-gray-600 py-[10px] px-[16px] text-sm	hover:text-indigo-600 focus:shadow-focus focus:text-indigo-600  selection:text-indigo-700",
);

export const dropdownContainer = cva(
  "relative p-0",
);

export const dropdownList = cva(
  "absolute transition-all top-full right-0 shadow-modal-combined rounded-[4px] p-2 text-gray-700 min-w-[100%] max-h-[200px] overflow-y-auto bg-white",
);

export const dropdownItem = cva(
  "flex gap-2 p-2 text-sm	items-center cursor-pointer rounded-md transition-all	hover:bg-gray-100",
);