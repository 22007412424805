// export const apiUrl =
//   process.env.NEXT_PUBLIC_API_URL ?? 'https://api.candao.io/api/'

// JAK POTRZEBA PROD API NA LOCALU ALBO DEVIE TO DAWAJ TAK O NA TRUE JAK NIE NO TO NIE CZYLI FALSE
// CO NIE ???
const devProdSwitch = false;

let origin;
if (typeof window !== "undefined") {
  origin = window.location.origin;
}

let api = "https://backend.socialxplorer.io/";

if (origin?.includes("staging")) {
  api = "https://staging-backend.socialxplorer.io/"
}

if (origin?.includes("dev") || origin?.includes("localhost")) {
  //api = devProdSwitch ? api : "https://dev-backend.socialxplorer.io/";
  api = devProdSwitch ? api : "https://api-dev.candao.io/";
}


export const apiUrl = api;
