import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../stores/store";
import { HYDRATE } from "next-redux-wrapper";

// Type for our state
export interface BuyCDOState {
  cdoForOneUsdc: {
    new_cdo_discount_price: string;
    new_cdo_price: string;
    old_cdo_price: string;
  };
  usdc: string;
  cdo: string;
}

// Initial state
const initialState: BuyCDOState = {
  cdoForOneUsdc: {
    new_cdo_discount_price: "",
    new_cdo_price: "",
    old_cdo_price: "",
  },
  usdc: "",
  cdo: "",
};

// Actual Slice
export const buyCDOSlice = createSlice({
  name: "buyCDO",
  initialState,
  reducers: {
    // Action to set the authentication status
    setCDOForOneUSDC(state, action) {
      state.cdoForOneUsdc = action.payload;
    },
    setUSDC(state, action) {
      state.usdc = action.payload;
    },
    setCDO(state, action) {
      state.cdo = action.payload;
    },
    setResetBuyCDO(state, action) {
      if (action.payload) {
        state.cdoForOneUsdc = {
          new_cdo_discount_price: "",
          new_cdo_price: "",
          old_cdo_price: "",
        };
        state.usdc = "";
        state.cdo = "";
      }
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.boarding,
        };
      },
    },
  },
});

export const { setCDOForOneUSDC, setUSDC, setCDO, setResetBuyCDO } =
  buyCDOSlice.actions;

export const selectOldPrice = (state: AppState) =>
  state.buyCDO.cdoForOneUsdc.old_cdo_price;

export const selectCDOForOneUSDC = (state: AppState) => {
  if (state.auth.user?.profile.cdo_discount_promotion_is_active)
    return state.buyCDO.cdoForOneUsdc.new_cdo_discount_price;
  else return state.buyCDO.cdoForOneUsdc.new_cdo_price;
};
export const selectCDO = (state: AppState) => state.buyCDO.cdo;
export const selectUSDC = (state: AppState) => state.buyCDO.usdc;

export default buyCDOSlice.reducer;
