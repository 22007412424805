import { cva } from 'class-variance-authority';

export const avatar = cva(
  "relative flex items-center justify-center rounded-lg overflow-hidden",
  {
    variants: {
      rounded: {
        true: "rounded-full",
      },
      color: {
        neutral: "bg-gray-100 text-gray-500",
        indigo: "bg-indigo-100 text-indigo-800",
        red: "bg-red-100 text-red-800",
        yellow: "bg-yellow-100 text-yellow-800",
        green: "bg-green-100 text-green-800",
        blue: "bg-blue-100 text-blue-800",
        purple: "bg-purple-100 text-purple-800",
        pink: "bg-pink-100 text-pink-800",
      },
      size: {
        tiny: "h-6 w-6 text-xs",
        small: "h-8 w-8 text-sm",
        base: "h-10 w-10 text-sm",
        medium: "h-12 w-12 text-m",
        large: "h-16 w-16 text-m",
        huge: "h-20 w-20 text-m",
      },
    },
  },
);
