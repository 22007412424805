import { IconName } from "@/types/icons";

export const getStatusIcon = (status: string): IconName => {
  const statusIcons: { [key: string]: string } = {
    loading: "loader",
    error: "x",
    success: "check",
  };

  return (statusIcons[status] || "x") as IconName;
};
