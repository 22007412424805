import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { button, buttonInner } from "./styles";
import { ButtonProps } from "./types";

export const Button = ({
  variant = "default",
  size = "base",
  className,
  children,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const isGradientVariant = variant === "gradient";

  const renderChildren = () => {
    if (isGradientVariant) {
      return (
        <>
          <span className={cn(buttonInner({ variant, className }))}></span>
          {children}
        </>
      );
    }

    return children;
  };

  return (
    <button
      className={cn(
        button({
          variant,
          size,
          className,
        }),
      )}
      {...props}
    >
      {renderChildren()}
    </button>
  );
};
