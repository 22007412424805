import { createContext, ReactNode, useCallback, useState } from "react";

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined,
);

export const ModalContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modals, setModals] = useState<ModalState>({});

  const showModal = useCallback((key: string) => {
    // handle disable scroll lock
    setModals((prev) => ({ ...prev, [key]: true }));
  }, []);

  const hideModal = useCallback((key: string) => {
    // handle enable scroll lock
    setModals((prev) => ({ ...prev, [key]: false }));
  }, []);

  return (
    <ModalContext.Provider value={{ modals, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};

type ModalContextType = {
  modals: ModalState;
  showModal: (key: string) => void;
  hideModal: (key: string) => void;
};

type ModalState = {
  [key: string]: boolean;
};
