import { request } from "../../utils/requestNew";

interface ReturnData {
  data: any;
  status: number;
}

export const headUsernameExist = async (params: {
  username: string;
}): Promise<ReturnData> => {
  const response = await request(
    `v1/users/`+params.username+ `/exists`,
    {
      method: "HEAD",
      headers: {
        "Content-Type": `application/json`
      },
    },
  );
  return response;
};
