import { StringLocale } from "yup/lib/locale";
import { authRequest } from "../../utils/request/authRequest";

interface UserInterface {
  id: string;
  username: string;
  profile: {
    avatarUrl: string;
    firstName: string;
    lastName: string;
  };
  wallets: {
    address: string;
  }
}


export const getUserByUsername = async (username: string): Promise<UserInterface> => {
  const response = await authRequest(`v1/users/`+username+`/info`, {
    method: "GET",
  });
  return response.data;
};
