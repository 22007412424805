import { cva } from 'class-variance-authority';

export const actionButton = cva(
  "flex items-center justify-center rounded-[4px]",
  {
    variants: {
      type: {
        default: "text-gray-500 hover:bg-gray-200",
        info: "text-blue-600 hover:bg-blue-200",
        danger: "text-red-600 hover:bg-red-200",
        success: "text-green-600 hover:bg-green-200",
        warning: "text-yellow-600 hover:bg-yellow-200",
        indigo: "text-indigo-600 hover:bg-indigo-200",
      },
      size: {
        small: "w-4 h-4",
        base: "w-6 h-6",
      },
    },
    defaultVariants: {
      type: "default",
      size: "base",
    },
  },
);
