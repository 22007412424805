//TODO: To refactor

import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";

import { TablerIcon } from "../tabler-icon";
import { dropdownButton } from "./styles";
import { dropdownContainer } from "./styles";
import { dropdownList } from "./styles";
import { dropdownItem } from "./styles";
import { DropdownButtonProps, itemListProps, soloItemProps } from "./types";

export const DropdownButton = ({
  onClickDropdown,
  onSelectDropdown,
  className,
  itemList,
  defaultItem,
}: PropsWithChildren<DropdownButtonProps>) => {
  const handleClick = () => {
    onClickDropdown?.();
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const handleSelectedValue = (e: soloItemProps) => {
    onSelectDropdown?.(e);
    setHasPickedValue(e)
  }

  const [hasPickedValue, setHasPickedValue] = useState<soloItemProps>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null) as any;

  useEffect(() => {
    const handleOutSideClick = (event: { target: any }) => {
      if (!ref.current?.contains(event.target)) {
        setTimeout(function () {
          setIsOpen(false);
        }, 100);
      }
    };

    window.addEventListener("mousedown", handleOutSideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [ref]);



  const ItemList = (e: any,index: number) => {
    return (
      <>
        <div
          className={cn(
            dropdownItem(),
            //TODO: Fix thats any type
          )}
          key={e.value+"_"+index}
          onClick={() => handleSelectedValue(e)}
        >
          {e.icon && <TablerIcon name={e.icon} className="h-4 w-4" />}
          <span>{e.title}</span>
        </div>
      </>
    );
  };

  return (
    <div className={cn(dropdownContainer(), className)}>
      <button
        className={cn(dropdownButton())}
        type="button"
        onClick={handleClick}
        ref={ref}
      >
        {hasPickedValue ? (
          <>
             {hasPickedValue.icon && <TablerIcon name={hasPickedValue?.icon} className="h-4 w-4" />}
            <span>{hasPickedValue?.title}</span>
          </>
        ) : (
          <>
             {defaultItem.icon && <TablerIcon name={defaultItem?.icon} className="h-4 w-4" />}
            <span>{defaultItem?.title}</span>
          </>
        )}
        {isOpen ? (
          <TablerIcon name={"chevron-down"} className="w-4" />
        ) : (
          <TablerIcon name={"chevron-up"} className="w-4" />
        )}
      </button>
      <div
        className={cn(
          dropdownList(),
          !isOpen && "pointer-events-none opacity-0",
        )}
      >
        {itemList?.map((e: any, index: number) => ItemList(e,index))}
      </div>
    </div>
  );
};
