import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

interface Props {
  children: JSX.Element;
}

const queryClient = new QueryClient();

const ReactQueryProvider = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryProvider;
