import {
  configureStore,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";

// Import your slices
import { boardingSlice } from "../slices/onBoarding.slice";
import { authSlice } from "../slices/auth.slice";
import { priorityPassSlice } from "../slices/priorityPass.slice";
import { buyCDOSlice } from "../slices/buyCDO.slice";
import { buyDomainSlice } from "../slices/buyDomain.slice";
import { configSlice } from "../slices/config.slice";
import { OnChainProfileSlice } from "../slices/onChainProfile.slice";

// Create the Redux store
const makeStore = () =>
  configureStore({
    reducer: {
      [boardingSlice.name]: boardingSlice.reducer,
      [authSlice.name]: authSlice.reducer,
      [priorityPassSlice.name]: priorityPassSlice.reducer,
      [buyCDOSlice.name]: buyCDOSlice.reducer,
      [buyDomainSlice.name]: buyDomainSlice.reducer,
      [configSlice.name]: configSlice.reducer,
      [OnChainProfileSlice.name]: OnChainProfileSlice.reducer,
    },
    devTools: process.env.NODE_ENV !== "production", // Enable devTools only in development mode
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Disable serializable check for middleware
      }),
  });

// Define the types for the store, state, and thunks
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

// Create the wrapper using `createWrapper` from `next-redux-wrapper`
export const wrapper = createWrapper<AppStore>(makeStore, { debug: process.env.NODE_ENV !== "production" });
