import { createContext, useContext } from "react";

import { TabsContextType } from "./types";

const TabsContext = createContext<TabsContextType | undefined>(undefined);

export const TabsProvider = TabsContext.Provider;

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabsContext must be used within a TabsProvider");
  }
  return context;
};
