import CryptoJS from "crypto-js";

var appConfig = {
    appID: process.env.NEXT_PUBLIC_APP_CONFIG === "prod" ? parseInt(process.env.NEXT_PUBLIC_ZEGO_APP_ID_PROD || "0")
        : process.env.NEXT_PUBLIC_APP_CONFIG === "staging" ? parseInt(process.env.NEXT_PUBLIC_ZEGO_APP_ID_STAGE || "0") : parseInt(process.env.NEXT_PUBLIC_ZEGO_APP_ID_DEV || "0"),
    serverSecret: process.env.NEXT_PUBLIC_APP_CONFIG === "prod" ? (process.env.NEXT_PUBLIC_ZEGO_SERVER_SECRET_PROD || "0")
        : process.env.NEXT_PUBLIC_APP_CONFIG === "staging" ? (process.env.NEXT_PUBLIC_ZEGO_SERVER_SECRET_STAGE || "0") : (process.env.NEXT_PUBLIC_ZEGO_SERVER_SECRET_DEV || "0"),
};


export function generateToken(userID: string, seconds: number) {
    if (!userID) return '';

    var time = (Date.now() / 1000) | 0;
    var body = {
        app_id: appConfig.appID,
        user_id: userID,
        nonce: (Math.random() * 2147483647) | 0,
        ctime: time,
        expire: time + (seconds || 7200),
    };
    var key = CryptoJS.enc.Utf8.parse(appConfig.serverSecret);
    var iv = Math.random().toString().substr(2, 16);
    if (iv.length < 16) iv += iv.substr(0, 16 - iv.length);

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(body), key, { iv: CryptoJS.enc.Utf8.parse(iv) }).toString();
    var ciphert = Uint8Array.from(Array.from(atob(ciphertext)).map((val) => val.charCodeAt(0)));
    var len_ciphert = ciphert.length;

    var uint8 = new Uint8Array(8 + 2 + 16 + 2 + len_ciphert);
    uint8.set([0, 0, 0, 0]);
    uint8.set(new Uint8Array(Int32Array.from([body.expire]).buffer).reverse(), 4);
    uint8[8] = 16 >> 8;
    uint8[9] = 16 - (uint8[8] << 8);
    uint8.set(Uint8Array.from(Array.from(iv).map((val) => val.charCodeAt(0))), 10);
    uint8[26] = len_ciphert >> 8;
    uint8[27] = len_ciphert - (uint8[26] << 8);
    uint8.set(ciphert, 28);

    var token = `04${btoa(String.fromCharCode(...Array.from(uint8)))}`;

    return token;
}