import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../stores/store";
import { HYDRATE } from "next-redux-wrapper";
import { UserDto } from "../../types/user.dto";

// Type for our state
export interface BoardingState {
  boardingStep: number;
  accessCode: string;
  invitedBy: UserDto | null;
}

// Initial state
const initialState: BoardingState = {
  boardingStep: 0,
  accessCode: "",
  invitedBy: null,
};

// Actual Slice
export const boardingSlice = createSlice({
  name: "boarding",
  initialState,
  reducers: {
    // Action to set the authentication status
    setBoardingState(state, action) {
      state.boardingStep = action.payload;
    },

    setInvitedBy(state, action) {
      state.invitedBy = action.payload;
    },

    setAccessCode(state, action) {
      state.accessCode = action.payload;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.boarding,
        };
      },
    },
  },
});

export const { setBoardingState, setInvitedBy, setAccessCode } =
  boardingSlice.actions;

export const selectBoardingState = (state: AppState) =>
  state.boarding.boardingStep;
export const selectInvitedBy = (state: AppState) => state.boarding.invitedBy;
export const selectAccessCode = (state: AppState) => state.boarding.accessCode;

export default boardingSlice.reducer;
