import { cva } from "class-variance-authority";

export const button = cva(
  "rounded-lg border border-transparent inline-flex items-center gap-2 justify-center",
  {
    variants: {
      variant: {
        default:
          "bg-white border-gray-200 text-gray-700 hover:text-indigo-600 hover:bg-gray-50 disabled:bg-gray-50 disabled:text-gray-400",
        primary:
          "bg-indigo-600 text-white hover:bg-indigo-700 disabled:bg-indigo-400",
        danger:
          "bg-red-600 hover:enabled:bg-red-700 text-white disabled:bg-red-400",
        borderless: "hover:enabled:bg-gray-100 disabled:text-gray-400",
        gradient:
          "text-white border-[rgba(0,0,0,0.08)] relative disabled:bg-white disabled:bg-opacity-[48%]",
      },
      size: {
        slim: "h-8 px-4 text-xs",
        base: "h-10 px-4 text-base",
        large: "h-12 px-6 text-md gap-4",
      },
      state: {
        disabled: "cursor-not-allowed",
      },
    },
  },
);

export const buttonInner = cva("inline-flex items-center gap-2", {
  variants: {
    variant: {
      default: "",
      primary: "",
      danger: "",
      borderless: "",
      gradient:
        "bg-gradient-to-r rounded-lg from-indigo-600 from-0% via-[#9061F9] via-56% to-[#FB923C] to-100% bg-no-repeat  bg-clip-padding absolute h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] left-[-1px] -z-10",
    },
    size: {
      slim: "h-8 px-4 text-xs",
      base: "h-10 px-4 text-base",
      large: "h-12 px-6 text-md gap-4",
    },
  },
});
