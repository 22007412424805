interface getAccessToken {
  accessToken: string;
  refreshToken: string;
}

const useUser = (): getAccessToken | undefined => {
  if (typeof window == "undefined") return;

  const getAccessToken = localStorage.getItem("candaoSession");

  if (!getAccessToken) return;

  if (typeof getAccessToken === "string") return JSON.parse(getAccessToken);

  return getAccessToken;
};

export default useUser;
