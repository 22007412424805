import { request } from "../../utils/requestNew";

export const getExists = async (params: {
  value: string;
  type: "email" | "walletAddress" | "username";
}): Promise<number> => {
  const response = await request(
    `v1/users/` + params.value + `/exists/` + params.type,
    {
      method: "HEAD",
      headers: {
        "Content-Type": `application/json`,
      },
    },
  );
  return response.status;
};
