import { Avatar, Button, TablerIcon } from "@/components/atoms";
import { Modal, Tabs, Textarea } from "@/components/molecules";
import { Input } from "@/v2/components/atoms/input";
import { cn } from "@/lib/utils";
import { patchMe, patchMev1 } from "@/queries/users/me.query";
import { ErrorDto, ErrorDtoNew } from "@/types/error.dto";
import router from "next/router";
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { v4 } from "uuid";
import Image from "next/image";
import { headUsernameExist } from "@/queries/users/usernameExist.query";
import useUserData from "@/hooks/useUserData";

const ProfileModalUpdateProfile = ({
  isUpdateProfileModalOpen,
  hideUpdateProfileModal,
}: any) => {
  const currentData = useUserData();
  if(!currentData?.profile) {
    return
  }
  const { profile } = currentData as any;
  const [hasData, setData] = useState({
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    username: currentData?.username,
    email: currentData?.email,
  });
  const [hasOldData, sethasOldData] = useState<any>();
  const [links, setLinks] = useState([""]); // Initialize with one empty link
  const [hasErrors, setErrors] = useState([]);
  const [isAvatarLoading, setAvatarLoading] = useState<boolean>(false);
  const [isBgLoading, setBgLoading] = useState<boolean>(false);

  const closeBlocker = () => {
    toast.error("You must first complete this step to use Candao.");
  };

  const patchUserData = useMutation(patchMev1, {
    onSuccess: (data) => {
      if(data.status == 204) {
        toast.success("Success! Your profile has been updated.");
        localStorage.removeItem("candaoUser"); //Refresh user data in storage
        //hideEditProfileModal()
        // setTimeout(function () {
        //   router.push("/my-profile");
        // }, 500);
      } else {
        if(data.data.error.code == 400) {
          setHasErrorData({ ...hasErrorData, "email": data.data.error.message });
        }
      }
    },
    onError: (e: ErrorDto) => {
      toast.error(
        e.response.data.error.translatedMessage
          ? e.response.data.error.translatedMessage
          : "Error. Unknown error, try again later.",
      );
      setErrors(e.response.data.error.errors);
      console.error(e);
    },
  });

  function extractDifferences(obj1: any, obj2: any) {
    const differences: any = {};

    Object.keys(obj2).forEach((key) => {
      if (!obj1.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
        differences[key] = obj2[key];
      }
    });

    return differences;
  }

  function submitAbout() {
    const rawData = hasData;

    const extractedData = extractDifferences(hasOldData, rawData);

    const hasUserName = extractedData.username
      ? { username: extractedData.username }
      : {};
    const hasEmail = extractedData.email
      ? { email: extractedData.email }
      : {};

    patchUserData.mutate({ profile: extractedData, ...hasUserName, ...hasEmail });
  }

  useEffect(() => {
    if (!hasOldData) {
      sethasOldData({
        ...hasData,
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        username: currentData?.username,
        email: currentData?.email,
      });
    }
  }, []);

  useEffect(() => {
    validateEmail(hasData?.email ? hasData?.email : "");
    validateString(hasData?.firstName ? hasData?.firstName : "", "firstName");
    validateString(hasData?.lastName ? hasData?.lastName : "", "lastName");
  }, [hasData]);

  const [hasUsernameStatus, setHasUsernameStatus] = useState<
    "none" | "load" | "error" | "success"
  >("none");
  const [hasUsernameErrorType, setHasUsernameErrorType] = useState<number>(0);
  const userNameErrorDescription =
    hasUsernameErrorType == 1
      ? "Username contains invalid characters"
      : "The specified username is taken";
  const userData = useUserData();

  useEffect(() => {
      validateEmail(currentData?.email);
    }, [])

  useEffect(() => {
    setHasUsernameStatus("none");
    // Conditions, reset states
    const delayDebounceFn = setTimeout(() => {
      if (hasData.username) {
        if (hasData.username == userData?.username) {
          setHasUsernameStatus("success");
        } else {
          setHasUsernameStatus("load");
          getUsernameStatus.mutate({ username: hasData.username });
        }
      } else {
        setHasUsernameStatus("none");
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [hasData.username]);

  const getUsernameStatus = useMutation(headUsernameExist, {
    retry: false,
    onSuccess: (data) => {
      setHasUsernameErrorType(0);
      setHasUsernameStatus("error");
    },
    onError: (e: ErrorDtoNew) => {
      if (e.code == 400) {
        setHasUsernameErrorType(1);
        setHasUsernameStatus("error");
      } else {
        setHasUsernameErrorType(0);
        setHasUsernameStatus("success");
      }
    },
  });

  const [hasErrorData, setHasErrorData] = useState<any>({});

  const changeFirstNameValidator = (e: any) => {
    const firstName = e.target.value;
    setData({ ...hasData, firstName });
    validateString(firstName, "firstName");
  };
  const changeLastNameValidator = (e: any) => {
    const lastName = e.target.value;
    setData({ ...hasData, lastName });
    validateString(lastName, "lastName");
  };

  const validateString = (string: string, name: string) => {
    if (!/^[a-zA-Z]+$/.test(string)) {
      setHasErrorData({
        ...hasErrorData,
        [name]: "Field can only contain letters",
      });
    } else {
      delete hasErrorData[name];

      setHasErrorData({ ...hasErrorData });
    }
  };

  const changeEmailValidator = (e: any) => {
    const email = e.target.value;
    setData({ ...hasData, email });
    validateEmail(email);
  };

  const validateEmail = (email: string) => {
    if (email.includes("@candao.io")) {
      setHasErrorData({
        ...hasErrorData,
        email: "Email must not be in candao.io domain",
      });
    } else if (
      !/[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/.test(email)
    ) {
      setHasErrorData({ ...hasErrorData, email: "Email must be valid" });
    } else {
      delete hasErrorData.email;

      setHasErrorData({ ...hasErrorData });
    }
  };

  return (
    <Modal
      isOpen={isUpdateProfileModalOpen}
      onClose={closeBlocker}
      className="max-w-lg"
    >
      <Modal.Header onClose={closeBlocker}>Update Profile</Modal.Header>
      <Modal.Body className="w-full max-w-[512px] p-0">
        <div className="flex flex-col">
          <div className="px-6 py-4">
            To continue using the Candao portal make sure that the following
            data is correct.
          </div>
          <div className="px-6 pb-8">
            <div className="flex flex-col gap-4">
              <Input
                title="First Name"
                placeholder="Satoshi"
                id="first-name"
                value={hasData.firstName}
                onChange={(e: any) => changeFirstNameValidator(e)}
                variant={hasErrorData.firstName && "danger"}
                prompt={hasErrorData.firstName && hasErrorData.firstName}
              />
              <Input
                title="Last Name"
                placeholder="Nakamoto"
                id="last-name"
                value={hasData.lastName}
                onChange={(e: any) => changeLastNameValidator(e)}
                variant={hasErrorData.lastName && "danger"}
                prompt={hasErrorData.lastName && hasErrorData.lastName}
              />

              <Input
                title="Username"
                placeholder="Nakamoto"
                id="username"
                //disabled={true}
                value={hasData.username}
                //helperText="You can't change your username"
                variant={
                  (hasUsernameStatus == "error" &&
                    userNameErrorDescription &&
                    "danger") ||
                  (hasErrorData?.username && !hasData.username && "danger")
                }
                prompt={
                  (hasUsernameStatus == "error" && userNameErrorDescription) ||
                  (hasErrorData?.username &&
                    !hasData.username &&
                    "Complete this field.")
                }
                status={
                  hasUsernameStatus == "error"
                    ? "x"
                    : hasUsernameStatus == "load"
                      ? "loading"
                      : hasUsernameStatus == "success"
                        ? "success"
                        : ""
                }
                onChange={(e: any) =>
                  setData({ ...hasData, username: e.target.value })
                }
              />
              <Input
                title="Email"
                id="email"
                value={hasData.email}
                onChange={(e: any) => changeEmailValidator(e)}
                variant={hasErrorData.email && "danger"}
                prompt={hasErrorData.email && hasErrorData.email}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex w-full justify-center gap-2">
          <Button
            variant="primary"
            disabled={
              Object.keys(hasErrorData).length > 0 ||
              hasUsernameStatus == "load" ||
              hasUsernameStatus == "error"
            }
            onClick={() => submitAbout()}
          >
            Save changes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileModalUpdateProfile;
