import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../stores/store";
import { HYDRATE } from "next-redux-wrapper";
import { ConfigResponse, SelectedChain } from "../../types/config.dto";

interface ConfigState extends ConfigResponse {
  selectedChain: SelectedChain | null;
}

const initialState: ConfigState = {
  chains: [],
  selectedChain: null,
  enable_blockchain_logic: true,
  early_birds_can_buy_priority_pass: true,
  is_early_birds_active: true,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setChains(state, action) {
      state.chains = action.payload;
    },
    setSelectedChain(state, action) {
      state.selectedChain = action.payload;
    },
    setEnableBlockchainLogic(state, action) {
      state.enable_blockchain_logic = action.payload;
    },
    setEarlyBirdsCanBuyPriorityPass(state, action) {
      state.early_birds_can_buy_priority_pass = action.payload;
    },
    setIsEarlyBirdsActive(state, action) {
      state.is_early_birds_active = action.payload;
    },

    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.boarding,
        };
      },
    },
  },
});

export const {
  setChains,
  setSelectedChain,
  setEnableBlockchainLogic,
  setIsEarlyBirdsActive,
  setEarlyBirdsCanBuyPriorityPass,
} = configSlice.actions;

export const selectChains = (state: AppState) => state.config.chains;

export const selectBlockExplorerLink = (state: AppState) =>
  state.config.selectedChain?.chain.block_explorer;

export const selectSelectedChainId = (state: AppState) =>
  state.config?.selectedChain?.chain.chain_id;

export const selectDecimals = (state: AppState) =>
  state.config.selectedChain?.decimals;

export const selectCandaoCoordinatorContractAddress = (state: AppState) => {
  if (state.config.selectedChain)
    return state.config.selectedChain.chain.contracts
      .candao_coordinator_address;
  return null;
};
export const selectInfinityPassContractAddress = (state: AppState) => {
  if (state.config.selectedChain)
    return state.config.selectedChain.chain.contracts
      .candao_infinity_pass_address;
  return null;
};
export const selectOnchainRefV2ContractAddress = (state: AppState) => {
  if (state.config.selectedChain)
    return state.config.selectedChain.chain.contracts
      .candao_referral_v2_address;
  return null;
};
export const selectCandaoHeroesContractAddress = (state: AppState) => {
  if (state.config.selectedChain)
    return state.config.selectedChain.chain.contracts.candao_nft_address;
  return null;
};

export const selectCandaoBadgesContractAddress = (state: AppState) => {
  if (state.config.selectedChain)
    return state.config.selectedChain.chain.contracts.candao_badges_address;
  return null;
};

export const selectCandaoReferralContractAddress = (state: AppState) => {
  if (state.config.selectedChain)
    return state.config.selectedChain.chain.contracts.candao_referral_address;
  return null;
};

export const selectSelectedChain = (state: AppState) =>
  state.config.selectedChain;

export const selectEnableBlockchainLogic = (state: AppState) =>
  state.config.enable_blockchain_logic;

export const selectEarlyBirdsCanBuyPriorityPass = (state: AppState) =>
  state.config.early_birds_can_buy_priority_pass;

export const selectIsEarlyBirdsActive = (state: AppState) =>
  state.config.is_early_birds_active;

export default configSlice.reducer;
