import axios from "axios";
import { apiUrl } from "../../queries/config/api.config";
import useUser from "../../hooks/useUser";

export async function authRequest(
  path: string,
  options?: any,
  body?: any,
  filters?: any,
) {
  let parsedBody;
  const url = new URL(`${apiUrl}${path}`);

  const getAccessToken = await useUser();

  // console.log(getAccessToken)
  if (filters) {
    Object.keys(filters).forEach((i) => {
      url.searchParams?.append(i, filters[i]);
    });
  }

  if (body instanceof FormData) {
    parsedBody = body;
  } else {
    parsedBody = JSON.stringify(body);
  }

  const requestOptions = {
    method: "get",
    data: parsedBody,
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken?.accessToken}`,
      ...options?.headers,
    },
  };

  // try {
  //   const response = await axios(url.href, requestOptions);
  // // console.log(response.status)
  //   return { data: response.data, status: response.status };
  // } catch (error: any) {
  //   const urlRefresh = new URL(`${apiUrl}${path}`);
  //   const requestRefreshTokenOptions = {
  //     method: "get",
  //     data: JSON.stringify({
  //       type: "refresh",
  //       refreshToken: getAccessToken?.refreshToken,
  //     }),
  //     ...options,
  //     headers: {
  //       "Content-Type": "application/json",
  //       ...options?.headers,
  //     },
  //   };
  //   await axios(urlRefresh.href, requestRefreshTokenOptions);

  //   const retriedResponse = await axios(url.href, requestOptions);
  //   return { data: retriedResponse.data, status: retriedResponse.status };
  // }

  try {
    const response = await axios(url.href, requestOptions);
    return { data: response.data, status: response.status };
  } catch (error: any) {
    const response = error.response;

    if (response.status !== 401) {
      return { data: response.data, status: response.status };
    }

    const urlRefresh = new URL(`${apiUrl}` + `v1/auth/token`);
    const requestRefreshTokenOptions = {
      method: "post",
      data: JSON.stringify({
        type: "refresh",
        refreshToken: getAccessToken?.refreshToken,
      }),
      headers: {
        "Content-Type": "application/json"
      },
    };

    const responseTokenRefresh = await axios(
      urlRefresh.href,
      requestRefreshTokenOptions,
    );

    if (responseTokenRefresh.status !== 200) {
      return {
        data: responseTokenRefresh.data,
        status: responseTokenRefresh.status,
      };
    }
      localStorage.setItem("candaoSession", JSON.stringify(responseTokenRefresh.data));

    const requestOptionsAgain = {
      method: "get",
      data: parsedBody,
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${responseTokenRefresh?.data.accessToken}`,
        ...options?.headers,
      },
    };

    const reResponse = await axios(url.href, requestOptionsAgain);
    return { data: reResponse.data, status: reResponse.status };
  }
}
