import React, { PropsWithChildren, useCallback, useMemo } from "react";

import { Tab } from "@/components/atoms";
import { useControlled } from "@/v2/hooks/use-controlled.hook";

import { TabsProvider, useTabsContext } from "./context";
import { TabItemProps, TabPanelProps, TabsProps } from "./types";

export const Tabs = ({
  children,
  initialActiveTab = 0,
  activeTab,
  onActiveTabChange,
}: PropsWithChildren<TabsProps>) => {
  const [value, onValueChange] = useControlled<number | undefined>({
    value: activeTab,
    initialValue: initialActiveTab,
  });

  const handleValueChange = useCallback(
    (newValue: number) => {
      onValueChange(newValue);
      onActiveTabChange?.(newValue);
    },
    [onValueChange, onActiveTabChange],
  );

  const context = useMemo(
    () => ({
      activeTab: value,
      onActiveTabChange: handleValueChange,
    }),
    [value, handleValueChange],
  );

  return <TabsProvider value={context}>{children}</TabsProvider>;
};

const TabsList = ({ children }: PropsWithChildren) => {
  const { activeTab, onActiveTabChange } = useTabsContext();

  return (
    <div className="flex">
      {React.Children.map(children, (child, index) =>
        React.isValidElement<TabItemProps>(child)
          ? React.cloneElement(child, {
              isActive: activeTab === index,
              onClick: () => onActiveTabChange(index),
            })
          : child,
      )}
    </div>
  );
};

const TabsContent = ({ children }: PropsWithChildren) => {
  const { activeTab } = useTabsContext();

  return React.Children.map(children, (child, index) =>
    React.isValidElement<TabPanelProps>(child)
      ? React.cloneElement(child, {
          isVisible: activeTab === index,
        })
      : child,
  );
};

const TabsTab = ({
  children,
  isActive,
  onClick,
}: PropsWithChildren<TabItemProps>) => {
  return (
    <Tab onClick={onClick} type="underline" isActive={isActive}>
      {children}
    </Tab>
  );
};

const TabsPanel = ({
  children,
  isVisible,
}: PropsWithChildren<TabPanelProps>) => {
  return isVisible ? children : null;
};

Tabs.List = TabsList;
Tabs.Content = TabsContent;
Tabs.Tab = TabsTab;
Tabs.Panel = TabsPanel;
