import { RefObject, useLayoutEffect } from "react";

const useAutosizeTextArea = (
  enableAutosize = false,
  textAreaRef: RefObject<HTMLTextAreaElement>,
  value: string,
) => {
  useLayoutEffect(() => {
    if (textAreaRef?.current && enableAutosize) {
      textAreaRef.current.style.height = "0";
      const scrollHeight = textAreaRef.current.scrollHeight;

      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value, enableAutosize]);
};

export default useAutosizeTextArea;
