//TODO: complete the component
import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { TablerIcon } from "../tabler-icon";
import { input, inputPrompt, inputWrapper } from "./styles";
import { InputProps } from "./types";
import { getStatusIcon } from "./utils";

export const Input = ({
  variant = "default",
  size = "base",
  placeholder,
  className,
  iconBefore,
  iconAfter,
  onChange,
  prompt,
  title,
  type,
  value,
  regex,
  status,
  as,
  ...props
}: PropsWithChildren<InputProps>) => {
  const statusIcon = getStatusIcon(status);
  const isTextarea = as === "textarea";

  const InputComponent = isTextarea ? "textarea" : "input";

  return (
    <div className="flex flex-col gap-2 px-0">
      {title && <div>{title}</div>}
      <div
        className={cn(
          inputWrapper({
            variant,
            size,
            className,
          }),
          isTextarea && "h-auto",
        )}
      >
        {iconBefore && (
          <TablerIcon name={iconBefore} size={16} color="#9CA3AF" />
        )}
        <InputComponent
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={value}
          className={cn(
            input({
              variant,
              className,
            }),
          )}
          {...props}
        />
        {iconAfter && <TablerIcon name={iconAfter} size={16} color="#9CA3AF" />}
        {status && (
          <TablerIcon
            name={statusIcon}
            size={16}
            color="#9CA3AF"
            className={cn(status == "loading" ? "animate-spin	" : "")}
          />
        )}
      </div>
      {prompt && (
        <div
          className={cn(
            inputPrompt({
              variant,
              className,
            }),
          )}
        >
          {prompt}
        </div>
      )}
    </div>
  );
};
