import { PropsWithChildren, useMemo, useState } from "react";

import { ActionButton } from "@/components/atoms";
import { cn } from "@/lib/utils";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import { CardHeaderProps } from "./types";

export const CardHeader = ({
  children,
  small,
  classList,
  canCollapse,
}: PropsWithChildren<CardHeaderProps>) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderCollapse = useMemo(() => {
    if (canCollapse) {
      return (
        <ActionButton
          onClickAction={toggleCollapse}
          className="text-indigo-600"
          size="small"
        >
          {isCollapsed ? <IconChevronDown /> : <IconChevronUp />}
        </ActionButton>
      );
    }
  }, [isCollapsed]);

  return (
    <header
      className={cn(
        "flex items-center justify-between border-b border-gray-200 px-4 font-medium text-gray-600",
        small ? "py-2 text-xs" : "py-4 text-base",
        classList,
      )}
    >
      {children}
      {renderCollapse}
    </header>
  );
};

export const HeadingComponent = ({ children }: PropsWithChildren) => {
  return <h3>{children}</h3>;
};

CardHeader.Heading = HeadingComponent;
