import { cva } from "class-variance-authority";

export const input = cva(
  "w-full outline-none bg-transparent py-2.5 px-3 border rounded-lg placeholder:text-gray-400 focus:border-indigo-600 focus:ring-1 focus:ring-indigo-600 focus:outline-offset-1 focus:outline-2 focus:outline-indigo-300 disabled:bg-gray-100 disabled:pointer-events-none text-base",
  {
    variants: {
      state: {
        default:
          "text-gray-900 placeholder:text-gray-400 border-gray-300 bg-gray-50 [&:not(:disabled)]:hover:border-gray-400",
        error:
          "text-red-900 bg-red-50 placeholder:text-red-800 border-red-600 [&:not(:disabled)]:hover:border-red-700",
      },
      size: {
        base: "h-10",
        large: "h-12",
      },
    },
    defaultVariants: {
      state: "default",
      size: "base",
    },
  },
);

export const inputLabel = cva("text-sm font-medium", {
  variants: {
    state: {
      default: "text-gray-700",
      disabled: "text-gray-400",
      error: "text-red-900",
    },
  },
  defaultVariants: {
    state: "default",
  },
});

export const inputHelperText = cva("text-xs", {
  variants: {
    state: {
      default: "text-gray-500",
      disabled: "text-gray-400",
      error: "text-red-600",
    },
  },
  defaultVariants: {
    state: "default",
  },
});
