import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../stores/store";
import { HYDRATE } from "next-redux-wrapper";
import { LoggedUserDto } from "../../types/auth/loggedUser.dto";

// Type for our state
export interface AuthState {
  name: string;
}

// Initial state
const initialState: AuthState = {
  name: "",
};

// Actual Slice
export const OnChainProfileSlice = createSlice({
  name: "onChainProfile",
  initialState,
  reducers: {
    // Action to set the authentication status
    setOnchainName(state, action) {
      state.name = action.payload;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.boarding,
        };
      },
    },
  },
});

export const { setOnchainName } = OnChainProfileSlice.actions;

export const selectOnChainName = (state: AppState) => state.onChainProfile.name;

export default OnChainProfileSlice.reducer;
