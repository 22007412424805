import { request } from "../../utils/requestNew";

interface ReturnData {
  code: string;
}

export const getAuth = async (params: {
  walletAddress: string;
  email: string;
}): Promise<ReturnData> => {
  const response = await request(
    `v1/auth`,
    {
      method: "POST",
      headers: {
        "Content-Type": `application/json`,
      },
    },
    {
      walletAddress: params.walletAddress,
      email: params.email,
    },
  );
  return response.data;
};
