import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../stores/store";
import { HYDRATE } from "next-redux-wrapper";

// Type for our state
export interface PPState {
  price: string;
  packagePrice: any;
  domain: string;
  selectedVariant: number;
  passVariants: any;
  reserved_id: string;
  calculatedCDO: string;
}

// Initial state
const initialState: PPState = {
  calculatedCDO: "",
  price: "",
  packagePrice: "",
  selectedVariant: 0,
  domain: "",
  passVariants: null,
  reserved_id: "",
};

// Actual Slice
export const priorityPassSlice = createSlice({
  name: "priorityPass",
  initialState,
  reducers: {
    // Action to set the authentication status
    setPrice(state, action) {
      state.price = action.payload;
    },
    setCalculatedCDO(state, action) {
      state.calculatedCDO = action.payload;
    },
    setPackagePrice(state, action) {
      state.packagePrice = action.payload;
    },
    setDomain(state, action) {
      state.domain = action.payload;
    },
    setPassVariants(state, action) {
      state.passVariants = action.payload;
    },
    setSelectedVariant(state, action) {
      state.selectedVariant = action.payload;
    },
    setReservedId(state, action) {
      state.reserved_id = action.payload;
    },
    setDefaultPriorityPassValues(state, action) {
      state.calculatedCDO = "";
      state.price = "";
      state.packagePrice = "";
      state.selectedVariant = 0;
      state.domain = "";
      state.passVariants = null;
      state.reserved_id = "";
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.boarding,
        };
      },
    },
  },
});

export const {
  setPrice,
  setDomain,
  setPassVariants,
  setSelectedVariant,
  setReservedId,
  setPackagePrice,
  setDefaultPriorityPassValues,
  setTransactionHash,
  setCalculatedCDO,
} = priorityPassSlice.actions;

export const selectPrice = (state: AppState) => state.priorityPass.price;
export const selectPackagePrice = (state: AppState) =>
  state.priorityPass.packagePrice;
export const selectDomain = (state: AppState) => state.priorityPass.domain;
export const selectSelectedVariant = (state: AppState) =>
  state.priorityPass.selectedVariant;
export const selectPassVariants = (state: AppState) =>
  state.priorityPass.passVariants;
export const selectReservedId = (state: AppState) =>
  state.priorityPass.reserved_id;
export const selectCalculatedCDO = (state: AppState) =>
  state.priorityPass.calculatedCDO;

export default priorityPassSlice.reducer;
