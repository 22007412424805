import Link from "next/link";
import { useRouter } from "next/router";

import { TablerIcon } from "@/components/atoms";
import { cn } from "@/lib/utils";

import { VerticalTabsProps } from "./types";

export const VerticalTabs = ({ tabs }: VerticalTabsProps) => {
  const router = useRouter();
  const currentRoute = router.asPath;

  return (
    <ul className="flex flex-col text-base text-gray-500">
      {tabs.map((tab) => {
        const isActive = currentRoute === tab.href;

        return (
          <li key={tab.name}>
            <Link
              href={tab.href}
              className={`${cn("flex items-center gap-2 border-l-[3px] border-transparent py-3 pl-[13px] pr-4 text-gray-500", isActive && "border-indigo-600 text-indigo-600", !isActive && "hover:text-gray-600")}`}
            >
              {tab.icon && (
                <TablerIcon
                  name={tab.icon}
                  className={`${cn(isActive && "stroke-indigo-600")}`}
                />
              )}
              {tab.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};