import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../stores/store";
import { HYDRATE } from "next-redux-wrapper";

export interface BuyDomainElement {
  domain: string;
  price: string;
  reservationToken: string;
}

// Type for our state
export interface BuyDomainState {
  domains: BuyDomainElement[];
  claimDomain: BuyDomainElement | null;
}

// Initial state
const initialState: BuyDomainState = {
  domains: [],
  claimDomain: null,
};

// Actual Slice
export const buyDomainSlice = createSlice({
  name: "buyDomain",
  initialState,
  reducers: {
    // Action to set the authentication status
    setDomains(state, action) {
      state.domains = action.payload;
    },
    setDomainToClaim(state, action) {
      state.claimDomain = action.payload;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.boarding,
        };
      },
    },
  },
});

export const { setDomains, setDomainToClaim } = buyDomainSlice.actions;

export const selectDomains = (state: AppState) => state.buyDomain.domains;
export const selectDomainToClaim = (state: AppState) =>
  state.buyDomain.claimDomain;

export default buyDomainSlice.reducer;
