import { useModal } from "@/v2/hooks/use-modal.hook";
import ErrorModal from "@/v2/modals/error";
import React from "react";
import { Toaster } from "react-hot-toast";

const ErrorModalProvider = () => {
  const {
    isOpen: isErrorModalOpen,
    showModal: showErrorModal,
    hideModal: hideErrorModal,
  } = useModal("error-modal");

  return <ErrorModal isErrorModalOpen={isErrorModalOpen} hideErrorModal={hideErrorModal} />;
};

export default ErrorModalProvider;
