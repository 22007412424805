import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../stores/store";
import { HYDRATE } from "next-redux-wrapper";
import { LoggedUserDto } from "../../types/auth/loggedUser.dto";

// Type for our state
export interface AuthState {
  user: LoggedUserDto | null;
}

// Initial state
const initialState: AuthState = {
  user: null,
};

// Actual Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Action to set the authentication status
    setLoggedUser(state, action) {
      state.user = action.payload;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.boarding,
        };
      },
    },
  },
});

export const { setLoggedUser } = authSlice.actions;

export const selectLoggedUser = (state: AppState) => state.auth.user;
export const selectPairCode = (state: AppState, address: string) => {
  const currentWallet = state.auth.user?.wallets?.find((wallet) => {
    return wallet.address.toUpperCase() === address.toUpperCase();
  });
  if (currentWallet) return currentWallet.pair_code;
  return null;
};
export const selectOnChainProfile = (state: AppState) =>
  state.auth.user?.on_chain_profile_active;
export const selectCanClaim = (state: AppState) =>
  state.auth?.user?.domain_to_claim;

export const selectCurrentWalletNfts = (state: AppState, address: string) => {
  const currentWallet = state.auth.user?.wallets?.find((wallet) => {
    return wallet.address.toUpperCase() === address.toUpperCase();
  });
  if (currentWallet) return currentWallet.nft_badges;
  return null;
};
export const selectCurrentWallet = (state: AppState, address: string) => {
  const currentWallet = state.auth.user?.wallets?.find((wallet) => {
    return wallet.address.toUpperCase() === address.toUpperCase();
  });
  if (currentWallet) return currentWallet;
  return null;
};

export const selectPriorityPassBadge = (state: AppState) => {
  if (state.auth.user)
    if (state.auth.user.wallets)
      return state.auth.user?.wallets.find((wallet) =>
        wallet.nft_badges.find((badge) => badge.name === "Priority Pass"),
      );
  return false;
};

export const selectInfinityPassBadge = (state: AppState) => {
  if (state.auth.user)
    if (state.auth.user.wallets)
      return state.auth.user?.wallets.find((wallet) =>
        wallet.nft_badges.find((badge) => badge.name === "Infinity Pass"),
      );
  return false;
};

export default authSlice.reducer;
