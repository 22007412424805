import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

import { PageHeaderProps } from './types';

export const PageHeader = ({
  children,
  className,
  as,
}: PropsWithChildren<PageHeaderProps>) => {
  const Component = as || "div";
  return (
    <Component
      className={cn(
        "overflow-hidden rounded-2xl border bg-white  px-4 py-4",
        className,
      )}
    >
      {children}
    </Component>
  );
};

const Heading = ({ children }: PropsWithChildren) => {
  return <h2 className="text-heading">{children}</h2>;
};

const SubHeading = ({ children }: PropsWithChildren) => {
  return <h2 className="text-base text-gray-600">{children}</h2>;
};

PageHeader.Heading = Heading;
PageHeader.SubHeading = SubHeading;
