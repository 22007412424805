import { cva } from "class-variance-authority";

export const link = cva("flex items-center gap-2 text-base", {
  variants: {
    state: {
      default: "text-indigo-600 hover:text-indigo-700",
      disabled: "cursor-none pointer-events-none text-indigo-400",
    },
  },
  defaultVariants: {
    state: "default",
  },
});
