import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

import { avatar } from './styles';
import { AvatarProps } from './types';

export const Avatar = ({
  rounded,
  color,
  size,
  username,
  avatarUrl,
  className,
  children,
}: PropsWithChildren<AvatarProps>) => {
  return (
    <div
      className={cn(
        avatar({
          rounded,
          color,
          size,
          className,
        }),
      )}
    >
      {avatarUrl ? <img src={avatarUrl} alt={username}  className="h-full w-full object-cover"/> : username ? username.substring(0, 1).toUpperCase() : children}
    </div>
  );
};
