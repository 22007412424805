import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { tab } from "./styles";
import { TabProps } from "./types";

export const Tab = ({
  onClick,
  isActive,
  children,
  variant,
  state,
  type,
  size,
  className,
}: PropsWithChildren<TabProps>) => {
  const tabState = isActive ? "active" : state;
// console.log(tabState, "tabState");

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(tab({ variant, state: tabState, type, size }), className)}
    >
      {children}
    </button>
  );
};
