import { authRequest } from "../../utils/request/authRequest";

export const checkUserData = async (): Promise<any> => {
  const response = await authRequest(
    `v2/users/check-data`,
    {
      method: "GET",
      headers: {
        "Content-Type": `application/json`,
      },
    },
  );
  return response.data;
};
