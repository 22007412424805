// TODO: https://github.com/tabler/tabler-icons/pull/1081

import { IconName } from "@/types/icons";

import { icons } from "./icons";

interface TablerIconProps {
  name: IconName;
  className?: string;
  size?: number;
  color?: string;
  stroke?: number;
}

export const TablerIcon = ({
  name,
  className,
  size,
  color = "currentColor",
  stroke,
}: TablerIconProps) => {
  const Icon = icons[name];

  if (Icon) {
    return (
      <Icon className={className} size={size} color={color} stroke={stroke} />
    );
  }

  return <p>Not found</p>;
};
