import React, { useEffect, useState } from "react";
import { useAccount, useConnect, useDisconnect, useMutation, useSignMessage } from "wagmi";
import { getWalletAddressConnected } from "../../queries/users/walletAddressConnected.query";
import { getAuthToken } from "../../queries/auth/token.query";
import { ErrorDto } from "../../types/error.dto";
import router, { useRouter } from "next/router";
import toast from "react-hot-toast";
import { getMe } from "../../queries/users/me.query";
import useUser from "../../hooks/useUser";
import getAuthData, {
  AuthPayload,
  AuthTokenPayload,
  AuthTokenRefreshPayload,
} from "./getAuthData";
import { usePathname, useSearchParams } from "next/navigation";
import { getAuth } from "@/queries/auth/auth.query";
import { getExists } from "@/queries/users/exists.query";
import { endSession } from "@sentry/nextjs";
import { useModal } from "@/v2/hooks/use-modal.hook";
import { useSelector } from "react-redux";
import { selectSelectedChainId } from "../../Redux/slices/config.slice";
import { checkUserData } from "@/queries/users/check-data.query";
import ProfileModalUpdateProfile from "@/v2/modals/update-profile";

//TODO: TO REFACTOR

const AuthPage = (props: any) => {
  const chainID = useSelector(selectSelectedChainId);
  const { connect, connectors } =
    useConnect({ chainId: Number(chainID) ?? 97 });
  const newConnector = connectors[0];
  const [hasAuthPayload, setHasAuthPayload] = useState<AuthPayload>({
    walletAddress: "",
    email: "",
  });
  const [hasAuthMode, setHasAuthMode] = useState<"login" | "refresh">("login");
  const [hasUnauthenticatedToken, setHasUnauthenticatedToken] =
    useState<boolean>(false);
  const [hasIntervalTick, setHasIntervalTick] = useState<boolean>(false);
  const [isRefreshingToken, setIsRefreshingToken] = useState<boolean>(false);

  const { isConnected, address, connector } = useAccount();

  const [hasCorrectData, setCorrectData] = useState<any>();

  const Web3AuthConnector =
    connector?.id === "web3auth" ? (connector as any)?.web3AuthInstance : "";
  const userInfo =
    Web3AuthConnector?.walletAdapters?.openlogin.openloginInstance.state
      .userInfo;
  const getToken = useUser();
  const pathname = usePathname();

  const isAuth = pathname?.includes("/auth/");

  useEffect(() => {
    if (connector && !isConnected) {
      sessionStorage.clear();
      localStorage.clear();
    }

    // console.log(connector)

    if (!getToken && isConnected && !isAuth) {
      const payload = { walletAddress: address as string, email: userInfo?.email ? userInfo.email : "", type: "walletAddress" as "walletAddress" };
      setHasAuthPayload(payload);
      loginUser.mutate({ value: address as string, type: "walletAddress" as "walletAddress" });
    }
  }, [connector]);

  useEffect(() => {
    //Get user data after every page refresh
    if (getToken) {
      userData.mutate();
      if(!hasCorrectData) {
        checkUserDataCorrect.mutate()
      }
    }
    setHasIntervalTick(!hasIntervalTick);

  }, []);

  useEffect(() => {
  if(hasCorrectData && hasCorrectData.includes("email") && !isUpdateProfileModalOpen) {
    showEditProfileModal()
  }
  }, [hasCorrectData]);

  useEffect(() => {
    if (hasUnauthenticatedToken && hasAuthMode == "refresh") {
      setIsRefreshingToken(true);
      authToken.mutate({
        walletAddress: address as string,
        refreshToken: getToken?.refreshToken as string,
      });
    }
  }, [hasUnauthenticatedToken]);
  const delay = (ms:number) => new Promise(res => setTimeout(res, ms));

  const loginUser = useMutation(getExists, {
    retry: false,
    onSuccess: (statusCode: number) => {
      handleLoginUserStatus(statusCode);
    },
    onError: async (e: ErrorDto) => {
      console.error(e);

      auth.mutate(hasAuthPayload as any);
      await delay(1000);
      handleLoginUserStatus(e.code);
    },
  });

    const auth = useMutation(getAuth, {
    retry: false,
    onSuccess: (data) => {
    },
    onError: (e: ErrorDto) => {
      console.error(e);
    },
  });
  //TUTAJ

  function handleLoginUserStatus(statusCode: number) {
    const refCode = localStorage.getItem("refCode");
    if (statusCode === 204) {
      //The wallet address is connected to the system
      router.push("/auth/login");
    }
    if (statusCode === 400) {
      // The wallet address is not valid
      router.push(
        "/auth/logout/?hasError=true&reason=Wallet error, you have been logged out",
      );
    }
    if (statusCode === 404) {
      //The wallet address is not connected to the system
      if (!refCode) {
        if(userInfo?.email) { 
            const payload = { value: userInfo?.email, type: "email" as "email" };
            validUserName.mutate(payload);
        } else {
        router.push(
          "/auth/logout/?hasError=true&reason=The wallet is not connected to the system",
        );}
      } else {
        router.push("/auth/register");
      }
    }
  }


  const validUserName = useMutation(getExists, {
    retry: false,
    onSuccess: async (statusCode: number) => {
      // Handling 1 == email valid, wallet address no
      router.push(
          "/auth/logout/?hasError=true&reason=The email address provided is linked to another wallet&errorCode=1",
        );

    },
    onError: async (e: ErrorDto) => {
      // Handling 2 == If u want account u need to register
      await router.push(
          "/auth/logout/?hasError=true&reason=The wallet is not connected to the system&errorCode=2",
        );
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (!isRefreshingToken) {
        const getCurrentTime = Math.floor(Date.now() / 1000);
        const getSessionObject = localStorage.getItem("candaoSession");
        const getExpireTime = JSON.parse(
          getSessionObject ? getSessionObject : "{}",
        )?.expireDate;

        if (getCurrentTime > getExpireTime) {
          setHasUnauthenticatedToken(true);
        }
      }
      setHasIntervalTick(!hasIntervalTick);
    }, 1000);
  }, [hasIntervalTick]);

  /** API CALLBACKS */

  const authToken = useMutation(getAuthToken, {
    retry: 3,
    onSuccess: (data) => {
      // console.log(data)
      localStorage.setItem(
        "candaoSession",
        JSON.stringify({
          ...data,
          expireDate: Math.floor(Date.now() / 1000) + 10 * 60,
        }),
      );
      if (hasUnauthenticatedToken) {
        setIsRefreshingToken(false);
        setHasUnauthenticatedToken(false);
        userData.mutate();
      }
    },
    onError: (e: ErrorDto) => {
      console.error(e);
      router.push(
        "/auth/logout/?hasError=true&reason=Authorization error, you have been logged out",
      );
    },
  });

  const userData = useMutation(getMe, {
    retry: false,
    onSuccess: (data) => {
      localStorage.setItem("candaoUser", JSON.stringify(data));
    },
    onError: (e: ErrorDto) => {
      console.error(e);
      if (e.response.status == 401) {
        setHasAuthMode("refresh");
        setHasUnauthenticatedToken(true);
      }
    },
  });


  
 const checkUserDataCorrect = useMutation(checkUserData, {
    retry: false,
    onSuccess: (data) => {
      setCorrectData(data)
    },
    onError: (e: ErrorDto) => {
      console.error(e);
    },
  });



  const {
    isOpen: isUpdateProfileModalOpen,
    showModal: showEditProfileModal,
    hideModal: hideUpdateProfileModal,
  } = useModal("update-profile-modal");

  return <>{props.children}
      <ProfileModalUpdateProfile isUpdateProfileModalOpen={isUpdateProfileModalOpen} hideUpdateProfileModal={hideUpdateProfileModal} />
  </>;
};

export default AuthPage;
