import { cva } from "class-variance-authority";

export const inputWrapper = cva(
  "flex bg-gray-50 border border-solid border-gray-300 rounded-lg items-center gap-3",
  {
    variants: {
      variant: {
        default:
          "bg-white border-gray-200 text-gray-700 hover:text-indigo-600 hover:bg-gray-50 disabled:bg-gray-50 disabled:text-gray-400",
        disabled:
          "bg-gray-50 text-gray-400",
        danger:
          "bg-red-50 border-red-600 text-red-900 placeholder:text-red-900",
      },
      size: {
        base: "h-10 px-4 text-base",
        large: "h-12 px-6 text-md gap-4",
      },
      state: {
        disabled: "cursor-not-allowed",
      },
    },
  },
);

export const input = cva("w-full outline-0 bg-transparent", {
  variants: {
    variant: {
      default: "text-gray-700 placeholder:text-gray-400",
      danger: " text-red-900 placeholder:text-red-800",
    },
  },
});

export const inputPrompt = cva("text-[12px] text-gray-500", {
  variants: {
    variant: {
      default: "",
      danger: "text-red-600",
    },
  },
});
