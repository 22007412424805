export const MainRole = ({ rolesList }: any) => {
  return (
    <div className="inline-flex items-center overflow-hidden rounded-[20px] bg-gradient-to-l from-[#CDDBFE] to-[#B4C6FC] p-[1px] text-xs text-indigo-600">


      {rolesList && rolesList.map(
        (data: any, index: number) =>
          (
           <div key={data+"_"+index} className="bg-radial-gradient rounded-[20px] bg-[#F3F4F6] px-2.5 py-0.5">
          {data}
        </div>
          ),
      )}
    </div>
  );
};
