import React from "react";

import { CountdownTile } from "./countdown-tile";
import { CountdownTimerProps } from "./types";

export const CountdownTimer = ({
  days,
  hours,
  minutes,
  seconds,
}: CountdownTimerProps) => {
  const formattedDays = String(days).padStart(2, "0");
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  const tiles = [
    { value: formattedDays, label: "Days" },
    { value: formattedHours, label: "Hours" },
    { value: formattedMinutes, label: "Minutes" },
    { value: formattedSeconds, label: "Seconds" },
  ];

  return (
    <div className="flex justify-center gap-2 ">
      {tiles.map(({ value, label }) => (
        <CountdownTile key={label} value={value} label={label} />
      ))}
    </div>
  );
};
